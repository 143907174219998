"use client";
import { useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import styles from "@/app/styles/Molecules/TapToPlayCover.module.scss";
import { PostCard } from "@/app/types/PostCard.type";
const PlayerVodV2 = dynamic(
	() => import("@/app/components/molecules/PlayerVodV2/PlayerVodV2")
);
const VideoDuration = dynamic(
	() => import("@/app/components/atoms/VideoDuration/VideoDuration")
);

type CoverTypeBig = {
	cover: PostCard;
	hero: boolean;
	isTapToPlay?: boolean;
	priority?: boolean;
	first?: boolean;
};

export default function TapToplayCoverBig({
	cover,
	hero,
	isTapToPlay,
	priority,
	first,
}: CoverTypeBig) {
	const [showPlayer, setShowPlayer] = useState<boolean>(false);
	const [src, setSrc] = useState<string>(
		cover?.thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"
	);
	return (
		<div
			className={
				first
					? `${styles?.imgWrapperHeroFirst} ${styles?.imgWrapperHero} imgWrapper`
					: hero
					? `${styles?.imgWrapperHero} imgWrapper`
					: `${styles?.imgWrapper} imgWrapper`
			}
			onClick={() => setShowPlayer(true)}
		>
			{!showPlayer && (
				<Image
					src={src}
					alt={cover?.thumbnail?.alt ?? "Imagen del video"}
					width={cover?.thumbnail?.width}
					height={cover?.thumbnail?.height}
					loading={priority ? "eager" : "lazy"}
					sizes="
          (min-width: 1920px) 22vw,
          (min-width: 1280px) 30vw,
          (min-width: 768px) 50vw, 
          (min-width: 420px) 40vw, 
          50vw
          "
					priority={priority}
					quality={100}
					onError={() => setSrc("/assets/placeholder_.svg")}
				/>
			)}
			{showPlayer && hero && (
				<section
					className={`${styles.captionHover} captionHover-opacity-transition`}
				>
					<div>
						<span>{cover?.term[0]?.name}</span>
						<h3>{cover?.title}</h3>
						<p>{cover?.summary}</p>
					</div>
				</section>
			)}
			{isTapToPlay && (
				<VideoDuration duration={cover?.duration || 0} isPlay={true} />
			)}
			{showPlayer && (
				<div className={styles?.playerWrapper}>
					<div className={styles?.playerContent}>
						<PlayerVodV2
							idVideo={cover?.cmsId}
							type="clips"
							cmsid={true}
							mute={true}
							autoplay={true}
							currentPlaceholder={cover?.thumbnail?.imageDesktopUrl?.webp}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

"use client";
import { useState } from "react";
import Image from "next/image";
import { Thumbnail } from "@/app/types/Thumbnail.type";

type ImgType = {
	thumbnail: Thumbnail;
	customsizes: string | undefined;
	priority?: boolean;
};
export default function ClientImage({
	thumbnail,
	customsizes,
	priority = false,
}: ImgType) {
	const [src, setSrc] = useState(
		thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"
	);
	const load = priority ? "eager" : "lazy";
	return (
		<Image
			src={src}
			alt={thumbnail?.alt ?? "Imagen default"}
			width={thumbnail?.width}
			height={thumbnail?.height}
			priority={priority}
			sizes={customsizes}
			quality={75}
			loading={load}
			onError={(e) => setSrc("/assets/placeholder_.svg")}
		/>
	);
}

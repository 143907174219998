import { Children, CSSProperties } from "react";
import Image from "next/image";
import dynamic from "next/dynamic";
import { GetHeroGrid } from "./interfaces/HeroGridResponse";
import heroGrid from "@/app/styles/Blocks/BloqueHeroGrid.module.scss";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetHeroGridQuery from "@/app/queries/getHeroGrid";
import { BlockPropsStructuredData } from "@/app/types/BlockProps.type";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import ListItem from "@/app/site-tools/structured-data/ListItem";
const WidgetTitle = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitle")
);
const InteractiveLink = dynamic(() => import("./InteractiveLink"));
const HeroGridCards = dynamic(() => import("./HeroGridCards"));
const TapToplayCover = dynamic(
	() => import("@/app/components/molecules/TapToPlayCover/TapToPlayCover")
);

export const BloqueHeroGrid = async ({
	type,
	id,
	position = 0,
	total,
	enabledStructureData = false,
}: BlockPropsStructuredData) => {
	const { data, ...rest } = await fetchApolloData(GetHeroGridQuery, {
		fromSite: "video",
		heroGridId: id,
		type,
	});
	if (rest?.errors) {
		if (rest?.errors.length > 0) {
			rest?.errors.forEach((error: any) => {
				console.error(error.message);
				if (error.message === "The user aborted a request.") {
					return <></>;
				}
			});
		} else {
			// console.log(rest?.errors, "<< rest.errors");
		}
	}

	if (!data?.getHeroGrid) return <></>;

	const {
		blockTitle,
		colorLine,
		noContent,
		content,
		subTitle,
		theme,
		cmsName,
		variant,
	}: GetHeroGrid = data?.getHeroGrid || [];

	const { principalContent, newsInfo } = content || {};

	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: theme,
		contenidosBloque: `${newsInfo?.length + 1} contenidos`,
		countWidget: total,
		content_name: "",
		descripcionBloque: cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: variant,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockTitle ?? undefined,
	};

	const loading =
		blockDataLayer?.posicionBloque === 0 ||
		blockDataLayer?.posicionBloque === 1 ||
		blockDataLayer?.posicionBloque === 2
			? "eager"
			: "lazy";
	const principalNews = {
		title: principalContent[0]?.title,
		duration: principalContent[0]?.videoDuration || 0,
		cmsId: principalContent[0]?.cmsId,
		isVideo: true,
		id: "",
		summary: "",
		date: "",
		term: [
			{
				name: "",
				url: "",
			},
		],
		path: principalContent[0]?.newsUrl,
		thumbnail: {
			alt: principalContent[0]?.altImage || "",
			caption: principalContent[0]?.editorialTitle || "",
			imageDesktopUrl: {
				webp: principalContent[0]?.image_16_9_desktop || "",
			},
			imageUrl: {
				webp: principalContent[0]?.image_16_9_desktop || "",
			},
			width: 500,
			height: 500,
		},
	};
	const ITEMS = enabledStructureData ? principalContent.concat(newsInfo) : [];
	const isDark = theme?.toLowerCase() === "dark";
	return (
		<>
			<section
				className={`${heroGrid[`heroGrid${noContent}`]}`}
				style={
					{
						"--backgroundHeroGrid": `${isDark ? "#00283B" : "#fff"}`,
						paddingTop: `${position === 0 ? "0px" : ""}`,
						paddingBottom: `${isDark ? "24px" : "0"}`,
						marginBottom: `${isDark ? "0" : "24px"}`,
					} as CSSProperties
				}
			>
				<div className={`${heroGrid[`heroGrid${noContent}--maxWidth`]}`}>
					<WidgetTitle
						title={blockTitle}
						text={subTitle}
						lineColor={colorLine}
						isDark={isDark}
					/>

					<div
						className={`${heroGrid[`heroGrid${noContent}__wrapperContent`]}`}
					>
						<div
							className={`${
								heroGrid[`heroGrid${noContent}__wrapperPrincipal`]
							}`}
						>
							{Children.toArray(
								principalContent?.map((news, i) => {
									const typeNews = news?.type?.split("--")[1] || "";
									const cType =
										typeNews === "news"
											? "NEW>"
											: typeNews === "liveblog"
											? "liveblog>"
											: "VOD>";
									blockDataLayer.content_name = cType;
									return (
										<figure
											className={`${
												heroGrid[`heroGrid${noContent}__wrapperCard`]
											}`}
										>
											<div
												className={`${
													heroGrid[`heroGrid${noContent}__wrapperImage`]
												}`}
											>
												{typeNews === "video" ? (
													<>
														<TapToplayCover
															post={principalNews}
															cardPosition={i}
															loading={loading}
															blockData={blockDataLayer}
														/>
													</>
												) : typeNews.toLowerCase() === "liveblog" ? (
													<>
														<InteractiveLink
															title={
																news?.altImage?.toUpperCase() ||
																news?.editorialTitle?.toUpperCase() ||
																"Noticia N+"
															}
															url={news?.newsUrl}
															blockData={blockDataLayer}
															cardPosition={i + 1}
														>
															<Image
																src={
																	news?.image_16_9_desktop ||
																	"/assets/placeholder_.svg"
																}
																height={100}
																width={300}
																className={`${
																	heroGrid[`heroGrid${noContent}__image`]
																}`}
																alt={
																	news.altImage ||
																	news.editorialTitle ||
																	news.title ||
																	""
																}
																sizes="(min-width:1440px) 38vw, (min-width:1280px) 31vw, (min-width: 1024px) 38vw, 50vw"
																loading={loading}
															/>

															<div
																className={`${
																	heroGrid[
																		`heroGrid${noContent}__liveblog__caption--on`
																	]
																}`}
															>
																<span
																	className={`${
																		heroGrid[
																			`heroGrid${noContent}__liveblog__captionText`
																		]
																	}`}
																>
																	LIVEBLOG
																</span>
																<span
																	className={`${
																		heroGrid[
																			`heroGrid${noContent}__liveblog__pulseIcon`
																		]
																	}`}
																/>
															</div>
														</InteractiveLink>
													</>
												) : (
													<>
														<InteractiveLink
															title={
																news?.altImage?.toUpperCase() ||
																news?.editorialTitle?.toUpperCase() ||
																"Noticia N+"
															}
															url={news?.newsUrl}
															blockData={blockDataLayer}
															cardPosition={i + 1}
														>
															<Image
																src={
																	news?.image_16_9_desktop ||
																	"/assets/placeholder_.svg"
																}
																height={100}
																width={300}
																className={`${
																	heroGrid[`heroGrid${noContent}__image`]
																}`}
																alt={
																	news.altImage ||
																	news.editorialTitle ||
																	news.title ||
																	""
																}
																sizes="(min-width:1440px) 38vw, (min-width:1280px) 31vw, (min-width: 1024px) 38vw, 50vw"
																loading={loading}
															/>
														</InteractiveLink>
													</>
												)}
											</div>

											<figcaption
												className={`${
													heroGrid[`heroGrid${noContent}__figcaption`]
												}`}
											>
												{typeNews === "video" ? (
													<span
														className={`${
															heroGrid[`heroGrid${noContent}__figcaption__tag`]
														}`}
														style={
															{
																"--heroGridTagColor": `${
																	theme?.toLowerCase() === "light"
																		? "#2D5D75"
																		: "#BFD1DA"
																}`,
															} as CSSProperties
														}
														title={news?.tagBlock?.toUpperCase() || "nmas"}
													>
														{news?.tagBlock || "nmas"}
													</span>
												) : (
													<span
														className={`${
															heroGrid[`heroGrid${noContent}__figcaption__tag`]
														}`}
														style={
															{
																"--heroGridTagColor": `${
																	theme?.toLowerCase() === "light"
																		? "#2D5D75"
																		: "#BFD1DA"
																}`,
															} as CSSProperties
														}
													>
														{news.tagBlock}
													</span>
												)}

												{typeNews === "video" ? (
													<>
														<p
															className={`${
																heroGrid[
																	`heroGrid${noContent}__figcaption__headLine`
																]
															}`}
															title={news?.editorialTitle || news?.title || ""}
															style={
																{
																	"--heroGridHeadLine": `${
																		theme?.toLowerCase() === "light"
																			? "#103D53"
																			: "#EDF4F8"
																	}`,
																} as CSSProperties
															}
														>
															{news?.editorialTitle || news?.title || ""}
														</p>
													</>
												) : (
													<InteractiveLink
														url={news?.newsUrl || ""}
														title={
															news?.editorialTitle ||
															news?.title ||
															"Noticia N+"
														}
														blockData={blockDataLayer}
														cardPosition={i + 1}
														isLink={false}
														aria-label={
															news?.editorialTitle ||
															news?.title ||
															"Noticia N+"
														}
													>
														<p
															className={`${
																heroGrid[
																	`heroGrid${noContent}__figcaption__headLine`
																]
															}`}
															title={news?.editorialTitle || news?.title || ""}
															style={
																{
																	"--heroGridHeadLine": `${
																		theme?.toLowerCase() === "light"
																			? "#103D53"
																			: "#EDF4F8"
																	}`,
																} as CSSProperties
															}
														>
															{news?.editorialTitle || news?.title || ""}
														</p>
													</InteractiveLink>
												)}
												{typeNews === "video" ? (
													<p
														className={`${
															heroGrid[
																`heroGrid${noContent}__figcaption__description`
															]
														}`}
														title={news?.description || "Noticia N+"}
														aria-label={news?.description || "Noticia N+"}
														style={
															{
																"--heroGridDescription": `${
																	theme?.toLowerCase() === "light"
																		? "#001621"
																		: "#EDF4F8"
																}`,
															} as CSSProperties
														}
													>
														{news?.description || ""}
													</p>
												) : (
													<InteractiveLink
														url={news?.newsUrl || ""}
														title={news?.description || "Noticia N+"}
														blockData={blockDataLayer}
														cardPosition={i + 1}
														isLink={false}
													>
														<p
															className={`${
																heroGrid[
																	`heroGrid${noContent}__figcaption__description`
																]
															}`}
															title={news?.description || "Noticia N+"}
															aria-label={news?.description || "Noticia N+"}
															style={
																{
																	"--heroGridDescription": `${
																		theme?.toLowerCase() === "light"
																			? "#001621"
																			: "#EDF4F8"
																	}`,
																} as CSSProperties
															}
														>
															{news?.description || ""}
														</p>
													</InteractiveLink>
												)}
											</figcaption>
										</figure>
									);
								})
							)}
						</div>
						<HeroGridCards
							newsInfo={newsInfo}
							theme={theme}
							noContent={noContent}
							loading={loading}
							blockData={blockDataLayer}
						/>
					</div>
				</div>
				{enabledStructureData ? ListItem(ITEMS) : ""}
			</section>
		</>
	);
};
export default BloqueHeroGrid;

import styles from "@/app/styles/SectionTitle/SectionTitle.module.scss";

export const SectionTitle = async ({ title, haveBreadcrumb }: any) => {
	return (
		<div
			className={
				haveBreadcrumb
					? `${styles?.wrapper} ${styles?.breadcrumb} `
					: `${styles.wrapper}`
			}
		>
			<h1 className={styles.title}>
				{title.replace("-", "")}
			</h1>
		</div>
	);
};
export default SectionTitle;

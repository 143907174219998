import dynamic from "next/dynamic";
import GetPolicyBlock from "@/app/queries/getPolicyBlock";
import parse from "html-react-parser";
import style from "@/app/styles/Blocks/BloqueTextoHtml.module.scss";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
const Breadcrumb = dynamic(() =>
	import("@/app/components/molecules/Breadcrumb/Breadcrumb")
);

export const BloqueTextoHtml = async ({ id, type }) => {
	const { data, ...rest } = await fetchApolloData(GetPolicyBlock, {
		policyBlockId: id,
		blockType: type,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const {
		getPolicyBlock: { body, title, hasBreadcrumb },
	} = data;

	return (
		<section className={`${style["textoHtml__wrapper"] + " bloqueTextoHtml"}`}>
			{hasBreadcrumb && <Breadcrumb themeColor={"light"} getLasted={true} />}
			<article className={`${style["textoHtml__wrapperText"]}`}>
				<h1>{title}</h1>
				{parse(body)}
			</article>
		</section>
	);
};
export default BloqueTextoHtml;

"use client";
import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { UnivLink } from "@/app/types/UnivLink.type";
import { Thumbnail } from "@/app/types/Thumbnail.type";
import { BlockDataType } from "@/app/types/BlockDataType";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";

type ImgSqreProps = {
	link: UnivLink;
	img: Thumbnail;
	blockData: BlockDataType;
	cardPosition: number;
	w: number;
	h: number;
	sizes?: string;
};
declare const window: WindowWithDataLayer;
export default function ImageLinkUniversal({
	link,
	img,
	blockData,
	cardPosition,
	w,
	h,
	sizes,
}: ImgSqreProps) {
	const [src, setSrc] = useState(
		img?.imageUrl?.webp || "/assets/placeholder_.svg"
	);
	const pathName = usePathname();
	return (
		<Link
			href={link?.uri}
			title={img?.alt ?? "N+"}
			target={link?.target}
			prefetch={false}
			onClick={() => handleDataLayerOnClick(blockData, cardPosition, pathName)}
		>
			<Image
				src={src}
				alt={img?.alt ?? "Imagen de N+"}
				width={w}
				height={h}
				sizes={sizes}
				onError={() => setSrc("/assets/placeholder_.svg")}
			/>
		</Link>
	);
}

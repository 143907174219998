"use client";
import { useRef, Children, CSSProperties } from "react";
import dynamic from "next/dynamic";
import { usePosition } from "@/app/hooks/usePosition";
import styles from "@/app/styles/Molecules/ShowsList.module.scss";
const ImageLink = dynamic(
	() => import("@/app/components/atoms/ImageLink/ImageLink")
);
const LinkSimple = dynamic(
	() => import("@/app/components/atoms/LinkSimple/LinkSimple")
);
const ButtonSlider = dynamic(
	() => import("@/app/components/atoms/ButtonSlider/ButtonSlider")
);
const InteractiveLink = dynamic(
	() => import("@/app/components/organisms/BloqueHeroGrid/InteractiveLink")
);

import type { BlockDataType, PostCard } from "@/app/types";

export default function ShowsList({
	shows,
	loading,
	blockData,
	isDark,
	isTalentPage,
}: {
	shows: PostCard[];
	loading: "eager" | "lazy";
	blockData: BlockDataType;
	isDark: boolean;
	isTalentPage: boolean;
}) {
	const elRef = useRef(null);
	const { scrollRight, scrollLeft, hasItemL, hasItemR } = usePosition(elRef);

	return (
		<div
			className={`${styles["showWrapper__container"]} ${
				isTalentPage ? styles["showWrapper__containerGrid"] : ""
			}`}
		>
			<div
				className={`${styles?.showWrapper}  ${styles["showWrapper__scrollableContainer"]}`}
				ref={elRef}
			>
				{Children.toArray(
					shows?.map((show: any, i: number) => {
						return (
							<figure
								key={show?.id}
								className={`${styles?.showWrapper__showCard}`}
							>
								<div className={styles?.showWrapper__showImgWrapper}>
									<InteractiveLink
										url={show?.path}
										title={show?.title}
										cardPosition={i + 1}
										blockData={{ ...blockData, contentTitle: show?.title }}
										isLink={false}
									>
										<ImageLink
											post={show}
											cardPosition={i}
											loading={loading}
											blockData={{ ...blockData, contentTitle: show?.title }}
										/>
									</InteractiveLink>
								</div>
								<figcaption
									className={styles?.showWrapper__showCaption}
									style={
										{
											"--textColor": `${isDark ? "#EDF4F8" : "#001621"}`,
										} as CSSProperties
									}
								>
									<LinkSimple
										link={{
											id: show?.id ?? "0",
											attr: "_self",
											title: show?.title,
											url: show?.path,
										}}
										blockData={{ ...blockData, contentTitle: show?.title }}
										cardPosition={i}
										className={`${styles["showWrapper__showCaption__caption"]}`}
									/>
									<span className={styles?.showWrapper__showSchedule}>
										{show?.schedule}
									</span>
								</figcaption>
							</figure>
						);
					})
				)}
			</div>
			{hasItemL && <ButtonSlider icon="arrowLeft" onClick={scrollLeft} />}
			{hasItemR && <ButtonSlider icon="arrowRight" onClick={scrollRight} />}
		</div>
	);
}

"use client";
import { useState } from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { BlockDataType } from "@/app/types/BlockDataType";
import { GWStoriesType } from "@/app/types/GWStoriesType";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import LightBox from "@/app/components/molecules/LightBox/LightBox";
import StoryPlayerLighbox from "@/app/components/molecules/StoryPlayerLighbox/StoryPlayerLighbox";
declare const window: WindowWithDataLayer;

interface ButtonImageProps {
	story: GWStoriesType;
	gwstories: [GWStoriesType];
	width: number;
	height: number;
	blockData?: BlockDataType;
	cardPosition: number;
}

export default function ButtonImage({
	story,
	gwstories,
	width,
	height,
	blockData,
	cardPosition,
}: ButtonImageProps) {
	const pathName = usePathname();
	const [ampStories, setAMPStories] = useState(gwstories);
	const [isligthBoxOpen, setIsLigthBoxOpen] = useState(false);

	function loadAMPStory(current: number) {
		setIsLigthBoxOpen(true);
		const selected = gwstories.splice(current, 1);
		gwstories.splice(0, 0, selected[0]);
		setAMPStories(gwstories);
		handleDataLayerOnClick(blockData, cardPosition, pathName, false);
	}

	function closeDialog() {
		setIsLigthBoxOpen(false);
	}

	const load = blockData?.posicionBloque! < 2 ? "eager" : "lazy";

	return (
		<>
			<button
				type="button"
				title={story?.title}
				onClick={() => loadAMPStory(cardPosition)}
			>
				<Image
					src={story?.storyImg ?? "/assets/placeholder_.svg"}
					alt={`Web story: ${story?.title}`}
					loading={load}
					width={width}
					height={height}
					onError={() => "/assets/placeholder_.svg"}
				/>
			</button>
			{isligthBoxOpen && (
				<LightBox onClose={() => closeDialog()}>
					<StoryPlayerLighbox
						stories={ampStories}
						closeDialog={() => closeDialog()}
					/>
				</LightBox>
			)}
		</>
	);
}

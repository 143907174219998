"use client";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { ImgLinkVerticalProps } from "@/app/types/ImgLinkProps.type";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";

declare const window: WindowWithDataLayer;
export default function ImageLinkVerticalCustom({
	post,
	cardPosition,
	loading,
	blockData,
	urlImage,
}: ImgLinkVerticalProps) {
	const pathName = usePathname();
	return (
		<Link
			href={post?.path}
			title={post?.title}
			prefetch={false}
			onClick={() => handleDataLayerOnClick(blockData, cardPosition, pathName)}
		>
			<Image
				src={urlImage || "/assets/placeholder_.svg"}
				width={post?.thumbnail?.width || 400}
				height={post?.thumbnail?.height || 400}
				alt={post?.thumbnail?.alt ?? "imagen default"}
				sizes="(min-width: 0) 35vw, (min-width: 414px) 14.5vw, (min-width: 768px) 14.3vw, (min-width: 1024px) 10.74vw, (min-width:1280px) 8.59vw"
				loading={loading}
			/>
		</Link>
	);
}
